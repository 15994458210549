import { Point } from "leaflet";
import Markers from "../public/markers";

export type IconProps = {
  iconUrl: string;
  iconSize?: Point;
  iconAnchor?: Point;
  popupAnchor?: Point;
  shadowUrl?: string;
  shadowSize?: Point;
  shadowAnchor?: Point;
};

const COMMON = {
  iconSize: new Point(42.5, 42.5),
  iconAnchor: new Point(25, 50),
};

export const ICON_STORE: IconProps = {
  ...COMMON,
  iconUrl: Markers.StoreMarkerURI,
};

export const ICON_PROCESSOR: IconProps = {
  ...COMMON,
  iconUrl: Markers.ProducerMarkerURI,
};

export const ICON_PRODUCER: IconProps = {
  ...COMMON,
  iconUrl: Markers.ProcessorMarkerURI,
};
