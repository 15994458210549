import {
  CardContent,
  Checkbox,
  Collapse,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useState, useEffect, useContext } from "react";
import Card from "../../atoms/Card/Card";
import { useTranslation } from "react-i18next";
import ProductListPageContext from "../../../context/ProductListPageContext";

const ProductFinderCategorySearch = (props: {
  categories: string[];
  onChange: (categoryFilter: string) => void;
}): JSX.Element => {
  const { categories, onChange } = props;

  const { categoryFilter } = useContext(ProductListPageContext);
  const [open, setOpen] = React.useState(false);
  const [_t] = useTranslation();
  const [checkboxes, setCheckboxes] = useState(
    [] as { checked: boolean; category: string }[]
  );

  useEffect(() => {
    if (
      categoryFilter &&
      categoryFilter.length > 0 &&
      categoryFilter !== null &&
      categories
    ) {
      setOpen(true);
      setCheckboxes(
        categories.map((category) => ({
          checked: categoryFilter.find(
            (filterCategory) => filterCategory === category
          )
            ? true
            : false,
          category,
        }))
      );
    } else if (categories) {
      setCheckboxes(
        categories.map((category) => ({
          checked: false,
          category,
        }))
      );
    }
  }, [categories, categoryFilter]);

  const handleClick = () => setOpen(!open);

  const handleToggle = (checked: boolean, category: string) => () =>
    onChange(category);

  return (
    <Card title={_t("categoryFilter")}>
      <CardContent onClick={handleClick} style={{ margin: "-5%" }}>
        {open ? (
          <Typography component={"div"} style={{ marginLeft: "30%" }}>
            {_t("showLess")}
            <ExpandMore aria-orientation={"horizontal"} />
          </Typography>
        ) : (
          <Typography component={"div"} style={{ marginLeft: "30%" }}>
            {_t("showEverything")}
            <ExpandLess />
          </Typography>
        )}
      </CardContent>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="nav" disablePadding>
          <Typography component={"div"}>
            {checkboxes.map((checkbox) => (
              <ListItem
                key={checkbox.category}
                dense
                button
                onClick={handleToggle(checkbox.checked, checkbox.category)}
              >
                <Checkbox
                  edge="start"
                  checked={checkbox.checked}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": "category-filter-" + checkbox.category,
                  }}
                />
                <p>{checkbox.category}</p>
              </ListItem>
            ))}
          </Typography>
        </List>
      </Collapse>
    </Card>
  );
};

export default ProductFinderCategorySearch;
