import { TextField, CardContent } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import React from 'react';
import { useTranslation } from "react-i18next";
import StoreDTOProductList from '../../../config/dtos/store.product-list.dto';
import Card from '../../atoms/Card/Card';

const ProductFinderStoreSearch = (props: { stores: StoreDTOProductList[], onChange: (storeFilters: StoreDTOProductList[]) => void }): JSX.Element => {
    const [_t] = useTranslation();
    const { stores, onChange } = props;
    const filterOptions = createFilterOptions<StoreDTOProductList>({
        trim: true,
        stringify: (store) => [store.name, store.city, store.zip].join(' ')
    });
    return <Card title={_t("filialefiltertitel")}>
        <CardContent>
            <Autocomplete style={{ margin: "-5%" }} multiple options={stores} onChange={(e, selected) => onChange(selected)} groupBy={store => store.city} getOptionLabel={store => store.name} renderInput={params => <TextField {...params} placeholder={_t("cityOrZip")} />} filterOptions={filterOptions} />
        </CardContent>
    </Card>;
};

export default ProductFinderStoreSearch;