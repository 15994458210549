import React from "react";
import { useStyles } from "./Timeline.style";

const TimelineBlock = (props: {
  title: string;
  content?: JSX.Element;
  actionArea?: JSX.Element;
  img?: JSX.Element;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.block}>
      <div className={classes.img}>{props.img}</div>
      <div className={classes.text}>
        <h2>{props.title}</h2>
        <p>{props.content}</p>
        <div>{props.actionArea}</div>
      </div>
    </div>
  );
};

export default TimelineBlock;
