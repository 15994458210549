import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  block: {
    display: "flex",
  },
  title: {
    marginLeft: 5,
  },
  container: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 26,
      height: "100%",
      width: 8,
      background: theme.palette.primary.light,
    },
  },
  img: {
    fill: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 60,
    height: 60,
    backgroundColor: "#154868",
    borderRadius: 30,
    zIndex: 25,
    // '@include breakpoint(md)': {
    //     order: 1,
    // }
  },
  text: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
}));
