import { Typography, TypographyProps } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const LoadingIndicator = (
  props: { label: string; interval: number } & TypographyProps
) => {
  const { label, interval, ...rest } = props;
  const [_t] = useTranslation();
  const [loadingStr, setLoadingStr] = useState("");

  useEffect(() => {
    const start = Date.now();
    const loadingInterval = setInterval(
      () =>
        setLoadingStr(
          ".".repeat(Math.abs((Math.ceil((Date.now() - start) / 250) % 6) - 2))
        ),
      250,
      []
    );
    setTimeout(() => {
      clearInterval(loadingInterval);
      setLoadingStr(_t("notAvailable"));
    }, 5000);
    return () => {
      clearInterval(loadingInterval);
      setLoadingStr("");
    };
  }, []);

  return (
    <Typography {...rest}>
      {loadingStr !== _t("notAvailable") ? label : ""}
      {loadingStr}
    </Typography>
  );
};

export default LoadingIndicator;
