import React from "react";
import TimelineBlock from "./TimelineBlock";
import { useStyles } from "./Timeline.style";

const Timeline = (props: { blocks: { title: string, content?: JSX.Element, actionArea?: JSX.Element, img?: JSX.Element }[] }) => {
    const classes = useStyles();

    return <div className={classes.container}>
        {props.blocks.map((block, i) => <TimelineBlock key={i} {...block} />)}
    </div>;
}

export default Timeline;