import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

// Import language files
import de from './translations/de.json'
import en from './translations/en.json'

const lang = 'de' //TODO: use wordpress language if available

const resources = {
    de: {translation:de},
    en: {translation:en},
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: lang,
        // keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n