/** External imports **/
// Axios import
import axios from "axios";

/** Internal imports **/
// API import
import api from "../config/api";
import AllLocaitons from "../config/dtos/all.locations";
import ProcessorDTOProcessorPage from "../config/dtos/processor.processor-page.dto";

// DTO imports
import ProductDTOProductDetail from "../config/dtos/product.product-detail.dto";
import ProductDTOProductList from "../config/dtos/product.product-list.dto";
import ProductDTOProductPage from "../config/dtos/product.product-page.dto";
import StoreDTOProductList from "../config/dtos/store.product-list.dto";
import StoreDTOProductPage from "../config/dtos/store.product-page.dto";
import Producer from "../config/models/producer.model";

// Model imports
import Store from "../config/models/store.model";

// Lookup table with the abbreviation for every canton
const cantonAbbreviations: { [key: string]: string } = {
  Aargau: "AG",
  "Appenzell Ausserrhoden": "AR",
  "Appenzell Innerrhoden": "AI",
  "Basel-Landschaft": "BL",
  "Basel-Stadt": "BS",
  Bern: "BE",
  Freiburg: "FR",
  Genf: "GE",
  Glarus: "GL",
  Graubünden: "GR",
  Jura: "JU",
  Luzern: "LU",
  Neuenburg: "NE",
  Nidwalden: "NW",
  Obwalden: "OW",
  Schaffhausen: "SH",
  Schwyz: "SZ",
  Solothurn: "SO",
  "Sankt Gallen": "SG",
  Tessin: "TI",
  Thurgau: "TG",
  Uri: "UR",
  Waadt: "VD",
  Wallis: "VS",
  Zug: "ZG",
  Zürich: "ZH"
}

/**
 * Service for accessing APIs.
 *
 * @author Joel Meccariello, Carlo Meier
 */
class ApiService {

  /**
   * Fetches all products from the backend.
   *
   * @returns An array containing the product list version of all product DTOs.
   * @author Joel Meccariello
   */
  getProducts = (): Promise<ProductDTOProductList[]> => new Promise((res, rej) => api.get("/products").then(({ data }) => res(data)).catch(rej));

  /**
   * Fetches the product associated with the given id and returns it as the DTO type given.
   * 
   * @param id The id of the product to return.
   * @param type The DTO type to return.
   * @returns The product associated with the given id as a DTO.
   * @author Joel Meccariello
   */
  getProductDTO(id: number, type: 'ProductPage'): Promise<ProductDTOProductPage>;
  getProductDTO(id: number, type: 'ProductDetail'): Promise<ProductDTOProductDetail>;
  getProductDTO(id: number, type: 'ProductPage' | 'ProductDetail'): Promise<ProductDTOProductPage | ProductDTOProductDetail> {
    return new Promise((res, rej) => api.get(`${type === 'ProductPage' ? "/products/" : "/products/product-detail/"}${id}`).then(({ data }) => res(data)).catch(rej));
  }

  /**
   * Fetches all stores from the backend.
   *
   * @returns An array containing the product list version of all store DTOs.
   * @author Joel Meccariello
   */
  getProductStores = (): Promise<StoreDTOProductList[]> => new Promise((res, rej) => api.get("/products/stores").then(({ data }) => res(data)).catch(rej));

  /**
   * Fetches all stores from the backend.
   *
   * @returns An array containing the product page version of all store DTOs.
   * @author Joel Meccariello
   */
  getStores = (): Promise<AllLocaitons> => new Promise((res, rej) => api.get("/stores").then(({ data }) => res(data)).catch(rej));

  /**
   * Fetches the store associated with the given id.
   * 
   * @param id Id of a store.
   * @returns The store object.
   * @author Joel Meccariello
   */
  getStore = (id: string): Promise<Store> => new Promise((res, rej) => api.get(`/stores/${id}`).then(({ data }) => res(data)).catch(rej));

  /**
   * Fetches the processor associated with the given id.
   *
   * @param id Id of a processor.
   * @returns The processor page version of the processor DTO.
   * @author Joel Meccariello
   */
  getProcessor = (id: string): Promise<ProcessorDTOProcessorPage> => new Promise((res, rej) => api.get(`/processors/${id}`).then(({ data }) => res(data)).catch(rej));

  /**
   * Fetches the producer associated with the given id.
   * 
   * @param id Id of a producer.
   * @returns The producer object.
   * @author Joel Meccariello
   */
  getProducer = (id: string): Promise<Producer> => new Promise((res, rej) => api.get(`/producers/${id}`).then(({ data }) => res(data)).catch(rej));

  /**
   * Fetches the coordinates of the search query within the bounds of Switzerland using the Nominatim API.
   *
   * @param query Search query as string.
   * @returns Array of possible results.
   * @see <a>https://nominatim.org/</a>
   * @author Joel Meccariello
   */
  searchLocation = (query: string): Promise<{ name: string, coords: { lat: number; lng: number }, bounds: { southWest: { lat: number, lng: number }, northEast: { lat: number, lng: number } } }[]> => new Promise((res, rej) => axios.get('https://nominatim.openstreetmap.org/search?format=json&countrycodes=ch&addressdetails=1&accept-language=de-CH&q=' + query).then(({ data }) => data.length > 0 ? res((data as { address: { house_number?: string, road?: string, village?: string, town?: string, city?: string, postcode?: string, state?: string }, lat: string, lon: string, boundingbox: string[], importance: number }[]).sort((a, b) => b.importance - a.importance).map(result => ({ name: (({ house_number, road, village, town, city, postcode, state } = result.address) => (road ? road + (house_number ? " " + house_number : "") : "") + (postcode || town || city || village ? (road ? ", " : "") + (postcode ? postcode : "") + (town || city || village ? (postcode ? " " : "") + (town ? town : city ? city : village) : "") + (state ? ", " : "") : "") + (state ? cantonAbbreviations[state] : ""))(), coords: { lat: parseFloat(result.lat), lng: parseFloat(result.lon) }, bounds: { southWest: { lat: parseFloat(result.boundingbox[0]), lng: parseFloat(result.boundingbox[3]) }, northEast: { lat: parseFloat(result.boundingbox[1]), lng: parseFloat(result.boundingbox[2]) } } }))) : rej("Couldn't get location")).catch(rej));
}

const apiService = new ApiService();

export default apiService;