/** External imports **/
// Material-UI imports
import { Container } from "@material-ui/core";
// Leaflet imports
import { LatLng } from "leaflet";
// React imports
import React, { useContext, useEffect, useState } from "react";
import AllLocaitons from "../../config/dtos/all.locations";
// DTO imports
import ProductDTOProductPage from "../../config/dtos/product.product-page.dto";
import StoreDTOProductPage from "../../config/dtos/store.product-page.dto";
// Context imports
import RoutingContext from "../../context/RoutingContext";
/** Internal imports **/
// Service imports
import ApiService from "../../services/ApiService";
// Molecule imports
import StoreMap from "../molecules/StoreMap/StoreMap";

/**
 * Displays a map and may display the manufacturing chain of a product.
 *
 * @returns The output to be rendered.
 * @author Joel Meccariello, Gianluca Daffré, Carlo Meier
 */
const StoreFinderPage = () => {
  // Context variables
  const query = useContext(RoutingContext)?.query;
  const productId = query?.get("productId");
  const storeId = query?.get("storeId");

  // Stateful variables
  const [product, setProduct] = useState(null as ProductDTOProductPage | null);
  const [center, setCenter] = useState<LatLng>();
  const [loaded, isLoaded] = useState(false);

  const [locations, setLocations] = useState<AllLocaitons>({
    stores: [],
    processors: [],
    producers: [],
  });

  const filterLocations = (array: StoreDTOProductPage[]) => {
    return array.reduce((unique: StoreDTOProductPage[], processor) => {
      if (
        !unique.some(
          (obj) =>
            obj.name === processor.name &&
            obj.latitude === processor.latitude &&
            obj.longitude === processor.longitude
        )
      ) {
        unique.push(processor);
      }
      return unique;
    }, []);
  };

  // Fetches a product by it's id if present
  // Otherwise fetches all stores
  useEffect(() => {
    productId
      ? ApiService.getProductDTO(parseInt(productId), "ProductPage").then(
          (res) => {
            isLoaded(true);
            setLocations({
              stores: res.stores,
              processors: [],
              producers: [],
            });

            setProduct(res);
          }
        )
      : ApiService.getStores().then((res) => {
          isLoaded(true);
          setLocations({
            stores: filterLocations(res.stores),
            processors: filterLocations(res.processors),
            producers: filterLocations(res.producers),
          });

          setProduct(null);
          const store = res.stores.find(({ id }) => `${id}` === storeId);
          if (store) setCenter(new LatLng(store.latitude, store.longitude));
        });
  }, [productId, storeId]);

  // Output to render
  return (
    <Container>
      {loaded && (
        <StoreMap locations={locations} product={product} center={center} />
      )}
    </Container>
  );
};

// Export this function
export default StoreFinderPage;
