import React from "react";
import { useMap } from "react-leaflet";

/**
 * Wraps a given component as map control to be used on a leaflet map.
 * 
 * @param position The position of the map control on the map.
 * @param setPosition Callback function for found coordinates.
 * @returns The map control element.
 * @author Joel Meccariello
 */
const MapControl = (props: { position: "bottomleft" | "bottomright" | "topleft" | "topright", children: JSX.Element }) => {
    const map = useMap();

    return <div id={"mapSearchControlParent"} onMouseEnter={() => {
        map.dragging.disable();
        map.doubleClickZoom.disable();
    }} onMouseLeave={() => {
        map.dragging.enable();
        map.doubleClickZoom.enable();
    }} className={{ bottomleft: "leaflet-bottom leaflet-left", bottomright: "leaflet-bottom leaflet-right", topleft: "leaflet-top leaflet-left", topright: "leaflet-top leaflet-right" }[props.position]}>
        <div className={"leaflet-control leaflet-bar"}>
            {props.children}
        </div>
    </div>;
}

/** Exports **/
export default MapControl;