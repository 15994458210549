/** External imports **/
// React imports
import React, { createContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Constant for the routing context
const RoutingContext = createContext({} as { openPage: (page: string, params?: any, toRemove?: string[]) => void, query: URLSearchParams | null, history: any });

// Exports the page attribute constant
export const PAGE_ATTR = 'pg';

// Exports an array containing all possible parameters
export const POSSIBLE_PARAMS = ["productId", "storeId", "processorId", "producerId", "markerId"];

// Exports the provider for the routing context
export const RoutingContextProvider = (props: { children: any }) => {

    // Parameters
    const { children } = props;

    // Context variables
    const history = useHistory();
    const location = useLocation();

    // Constant for the URL parameters
    const query = location ? new URLSearchParams(location.search) : null;

    /**
     * Displays the page associated with the given path.
     * 
     * @param page The path associated with a page.
     * @param params The URL parameters to pass.
     * @param omit The URL parameters to omit.
     * @author Joel Meccariello, Carlo Meier
     */
    const openPage = (page: string, params?: object) => {

        // URL parameter object initialized with either the previous URL parameter object or a blank one
        const reqParams = query ? query : new URLSearchParams();

        // Sets the page parameter
        reqParams.set(PAGE_ATTR, page);

        // Removes unwanted parameters (if any)
        ((param = POSSIBLE_PARAMS.find(possibleParam => reqParams.has(possibleParam))) => {
            if (param) reqParams.delete(param);
        })();

        // Adds new parameters (if any)
        if (params) Object.entries(params).forEach(entry => reqParams.set(entry[0], entry[1]));

        // Adds the previous page to the history
        history.push(location.pathname + '?' + reqParams.toString());
    }

    // Output to be rendered
    return <RoutingContext.Provider value={{
        openPage,
        query,
        history,
    }}>
        {children}
    </RoutingContext.Provider>
}

// Exports the constant for the routing context
export default RoutingContext;