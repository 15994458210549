/** External imports **/
// Material-UI imports
import { makeStyles } from "@material-ui/core/styles";

// Exports the custom styling
export default makeStyles(theme => ({
    title: {
        margin: "0 !important",
        fontSize: "150%",
        textAlign: "center",
        fontWeight: 700,
        color: "#144669"
    },
    distance: {
        margin: "0 !important",
        textAlign: "right",
        color: "#666",
        fontSize: "90%"
    },
    phoneNumber: {
        margin: "0 !important"
    },
    businessHours: {
        margin: "0 !important",
        fontWeight: 800
    },
    button: {
        "&:hover": {
            backgroundColor: "#154868",
        },
        backgroundColor: "#154868",
        color: "#fff",
        minWidth: "max-content",
        height: 30,
    },
    card: {
        boxShadow: "none"
    },
    cardContent: {
        padding: 0
    }
}));