import { Card as MuiCard, CardContent, CardHeader } from "@material-ui/core";
import React from "react";
import useStyles from "./Card.style";

const Card = (props: CardProps) => {
  const { children, title } = props;
  const classes = useStyles();

  return (
    /* <div className={classes.container}>
    //     <div className={classes.header}>{title}</div>
    //     <div className={classes.content}>{children}</div>
    // </div>
    // 
    */

    <MuiCard className={classes.card}>
      <CardHeader className={classes.header} title={title}></CardHeader>
      <CardContent>
        {children}
      </CardContent>
    </MuiCard>
  );
};

export interface CardProps {
  children: any;
  title: string;
}

export default Card;
