import {
  CardContent,
  TextField,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Card from "../../atoms/Card/Card";

const ProductFinderSearch = (props: { search: string, onChange: (search: string) => void }) => {
  const { search, onChange } = props;
  const [_t] = useTranslation();

  return <Card title={_t("searchProduct")}>
    <CardContent style={{ margin: "-5%" }} >
      <TextField
        fullWidth={true}
        placeholder={_t("searchProductPlaceHolder")}
        onChange={e => onChange(e.target.value)}
        value={search}
      />
    </CardContent>
  </Card>;
}

export default ProductFinderSearch;