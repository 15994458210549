import React from 'react';
import ReactDOM from 'react-dom';
import MapPage from "./components/pages/StoreFinderPage";
import ProductListPage from "./components/pages/ProductListPage";
import NaturliPage from "./components/pages/NaturliPage";
import {BrowserRouter as Router} from 'react-router-dom'
import {RoutingContextProvider} from "./context/RoutingContext";
import {SnackbarProvider} from "notistack";
import theme from "./config/theme";
import {ThemeProvider} from "@material-ui/core";
import StoreMap from './components/molecules/StoreMap/StoreMap';
import {ProductListPageContextProvider} from "./context/ProductListPageContext";
import CategorySearch from "./components/molecules/product-finder-category-search/product-finder-category-search.view";
import './i18n'

require('leaflet/dist/leaflet.css')
require('react-leaflet-markercluster/dist/styles.min.css')

const mappings = {
    naturli_stores: <SnackbarProvider>
        <MapPage/>
    </SnackbarProvider>,
    naturli: <Router>
        <ProductListPageContextProvider>
        <RoutingContextProvider>
            <SnackbarProvider>
                <NaturliPage/>
            </SnackbarProvider>
        </RoutingContextProvider>
        </ProductListPageContextProvider>
    </Router>,
}

for (let entry of Object.entries(mappings)) {
    let el = document.getElementById(entry[0])
    if (el) {
        ReactDOM.render(
            <React.StrictMode>
                <ThemeProvider theme={theme}>
                    {entry[1]}
                </ThemeProvider>
            </React.StrictMode>
            , el
        )
    }
}