import React, { useContext } from 'react';
import MuiButton from '@material-ui/core/Button';
import RoutingContext from '../../../context/RoutingContext';
import useStyles from './Button.style';

const CardButton = (props: { child?: JSX.Element, goToURL?: { href: string, target?: string }, goToPage?: { page: string, params?: any }, variant?: 'text' | 'outlined' | 'contained', color?: 'default' | 'inherit' | 'primary' | 'secondary', onClick?: () => void }) => {
    const { child, goToPage, variant, color, goToURL, onClick } = props;

    const { openPage } = useContext(RoutingContext);

    const classes = useStyles();

    const openURL = (href: string, target?: string) => /* window.open(href, target ? target : '_self') */ undefined;

    return <MuiButton onClick={() => onClick ? onClick() : goToPage ? openPage(goToPage.page, goToPage?.params) : (goToURL ? openURL(goToURL.href, goToURL.target) : undefined)} variant={variant} color={color} className={classes.crd_btn}>
        {child}
    </MuiButton>;
}

export default CardButton;