import { Container, Grid, Typography } from "@material-ui/core";
import { Icon, LatLng, Point } from "leaflet";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductDTOProductPage from "../../../config/dtos/product.product-page.dto";
import {
  ICON_PROCESSOR,
  ICON_PRODUCER,
  ICON_STORE,
} from "../../../config/icons";
import RoutingContext from "../../../context/RoutingContext";
import ApiService from "../../../services/ApiService";
import Map from "../../atoms/Map/Map";
import ProductDetails from "../../atoms/product-details/product-details.view";
import ManufacturingChain from "../../molecules/ManufacturingChain/ManufacturingChain";
import useClasses from "./ProductDetailPage.style";

const ProductDetailPage = () => {
  const productId = useContext(RoutingContext).query?.get("productId");
  const classes = useClasses();
  const [_t] = useTranslation();

  const [product, setProduct] = useState<ProductDTOProductPage>();
  const [centerId, setCenterId] = useState<number>();

  const [rerender, setRerender] = useState(false);

  const getLatLngFromCenterId = () => {
    if (product) {
      let producer = product.processor.producers.find(
        (producer) => producer.id === centerId
      );
      if (producer)
        return new LatLng(
          producer.latitude + (rerender ? 0 : 0.00001),
          producer.longitude
        );
      else return undefined;
    }
    return undefined;
  };

  const getMarkerGroup = () => {
    return product && product !== null
      ? [
          {
            name: "producer",
            markers: product.processor.producers
              ? product.processor.producers.map((producer) => ({
                  icon: new Icon(ICON_PRODUCER),
                  bundle: producer,
                  position: new Point(producer.latitude, producer.longitude),
                }))
              : [],
          },
          {
            name: "store",
            markers: product.stores.map((store) => ({
              icon: new Icon(ICON_STORE),
              bundle: store,
              position: new Point(store.latitude, store.longitude),
            })),
          },
          {
            name: "processor",
            markers: [
              {
                icon: new Icon(ICON_PROCESSOR),
                bundle: product.processor,
                position: new Point(
                  product.processor.latitude,
                  product.processor.longitude
                ),
              },
            ],
          },
        ]
      : [];
  };

  useEffect(() => {
    if (productId && !isNaN(+productId)) {
      ApiService.getProductDTO(parseInt(productId), "ProductPage").then(
        setProduct
        ).catch(() => {});
    }
  }, [productId]);

  return product ? (
    <Container>
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid container item xs={10}>
          <Grid item xs={12}>
            <Typography
              component={"div"}
              variant={"h4"}
              align={"center"}
              paragraph={true}
            >
              {product.name ?? _t("noNameAvailable")}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography component={"div"}>
              <h1>{_t("Manufacturingchain")}</h1>
            </Typography>
            <ManufacturingChain
              product={product}
              setCenterId={setCenterId}
              setRerender={setRerender}
              rerender={rerender}
            />
          </Grid>
          <Grid item xs={4}>
            <ProductDetails className={classes.productDetails} {...product} />
          </Grid>
          <Grid item xs={12}>
            {product.processor.latitude && product.processor.longitude && (
              <Map
                center={new LatLng(
                  product.processor.latitude + (rerender ? 0 : 0.00001),
                  product.processor.longitude
                )}
                markerGroups={getMarkerGroup()}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  ) : (
    <Container>
    <Grid container>
      <Grid item xs={12}>
        <Typography
          component={"div"}
          variant={"h4"}
          align={"center"}
          paragraph={true}
        >
          {_t("ProductCoundNotBeFound")}
        </Typography>
      </Grid>
    </Grid>
  </Container>
  );
};

export default ProductDetailPage;
