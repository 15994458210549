/** External imports **/
// Leaflet imports
import { Icon, IconOptions, LatLng, Point } from 'leaflet';

// React imports
import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet';

/** Internal imports **/
// Config imports
import StoreDTOProductPage from '../../../config/dtos/store.product-page.dto';
import ProcessorDTOProductPage from '../../../config/dtos/processor.product-page.dto';
import ProducerDTO from '../../../config/dtos/producer.dto';
import { IconProps } from '../../../config/icons';

// Style imports
import useStyles from './Map.style';

// DTO imports
import MapMarkers from './MapMarkers/MapMarkers';

// Atom imports
import MapSearch from './MapSearch/MapSearch';
import MapLegend from './MapLegend/MapLegend';

/** Constants **/
// The default center of the map is set to Zurich main station.
export const DEFAULT_CENTER = new LatLng(47.3778579, 8.5403226);

/**
 * Displays a map with markers for every store, processor and producer.
 *
 * @param props Contains the properties of the map and the array of markers.
 * @returns The map where the stores, processors and producers are displayed.
 * @author Joel Meccariello
 */
const MapComponent = (props: { marker?: { position: LatLng, icon: IconProps }, markerGroups?: { name: string, markers: { bundle?: StoreDTOProductPage | ProcessorDTOProductPage | ProducerDTO, position: Point, icon: Icon<IconOptions> }[] }[], center?: LatLng }) => {
    // Params
    const { marker, markerGroups, center } = props;

    // Context variables
    const classes = useStyles();

    // Stateful variables
    const [position, setPosition] = useState(DEFAULT_CENTER);
    const [location, setLocation] = useState<Point | null>(null)

    // Set the map's center
    useEffect(() => {
        if (marker) setPosition(marker.position);
        else if (center) setPosition(center);
        else if (location) setPosition(new LatLng(location.x, location.y));
    }, [marker, center, location]);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(pos => setLocation(new Point(pos.coords.latitude, pos.coords.longitude)));
    }, []);

    const defaultZoomFactor = 12;
    // Output to be rendered
    return <MapContainer
        className={classes.map}
        center={position}
        zoom={defaultZoomFactor}
        tap={false}
    >
        <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            noWrap
        />
        {markerGroups ? markerGroups.map((markerGroup, i) => <MapMarkers key={i} position={position} markerGroup={markerGroup} location={location} />) : (marker && <Marker position={marker.position} icon={new Icon(marker.icon)} />)}
        <MapSearch position={"topright"} />
        {markerGroups && <MapLegend position={"bottomleft"} data={markerGroups.map(({ name, markers }) => `${name}${markers.length > 1 ? "s" : ''}`)} />}
        {markerGroups && <MapUtil position={position} markers={markerGroups.flatMap(({ markers }) => markers.filter(({ position }) => position.x && position.y).map(({ position }) => ({ x: position.x, y: position.y })))} />}
    </MapContainer>;
}

const MapUtil = (props: { markers: { x: number, y: number }[], position: LatLng|null }) => {
    const { markers, position } = props;
    const map = useMap();
    const minZoomFactor = 8;

    useEffect(() => {
        if (position && !(position.lat === 0 && position.lng === 0)) map.setView(position);
      }, [position]);    

    useEffect(() => {
        if (markers?.length) {
            const bounds = markers.reduce((bounds, { x, y }) => [[bounds[0][0] > x ? x : bounds[0][0], bounds[0][1] > y ? y : bounds[0][1]], [bounds[1][0] < x ? x : bounds[1][0], bounds[1][1] < y ? y : bounds[1][1]]] as [[number, number], [number, number]], [[180, 90], [-180, -90]] as [[number, number], [number, number]]);
            map.setMinZoom(map.getBoundsZoom(bounds) - 1 > minZoomFactor ? map.getBoundsZoom(bounds) - 1 : minZoomFactor);
            let addSize = 0.5;
            map.setMaxBounds([
                [bounds[0][0] - addSize / 2, bounds[0][1] - addSize],
                [bounds[1][0] + addSize, bounds[1][1] + addSize],
              ]);
        }
    }, [markers, map]);

    return <></>;
}

// Exports this function
export default MapComponent;