import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
    icon: {
        maxHeight: 25
    },
    wrapper: {
        minWidth: "auto",
        padding: 5
    },
    item: {
        padding: 5
    }
}));