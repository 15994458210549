import {
  Button,
  Card,
  Container,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProcessorDTOProcessorPage from "../../config/dtos/processor.processor-page.dto";
import ProductDTO from "../../config/dtos/product.product-list.dto";
import StoreDTOProductList from "../../config/dtos/store.product-list.dto";
import ProductListPageContext from "../../context/ProductListPageContext";
import RoutingContext from "../../context/RoutingContext";
import ApiService from "../../services/ApiService";
import ProductView from "../atoms/product/product.view";
import CategorySearch from "../molecules/product-finder-category-search/product-finder-category-search.view";
import ProductSearch from "../molecules/product-finder-search/product-finder-search.view";
import StoreSearch from "../molecules/product-finder-store-search/product-finder-store-search.view";

function ProductListPage(): JSX.Element {
  const { openPage, query } = useContext(RoutingContext);
  const storeId = query ? query.get("storeId") : undefined;
  const productId = query ? query.get("productId") : undefined;
  const processorId = query ? query.get("processorId") : undefined;
  const [processor, setProcessor] = useState<ProcessorDTOProcessorPage>();
  const [store, setStore] = useState<StoreDTOProductList>();
  const [products, setProducts] = useState([] as ProductDTO[]);
  const [search, setSearch] = useState("");
  const [categories, setCategories] = useState([] as string[]);
  const { prevProductId, categoryFilter, addFilter } = useContext(
    ProductListPageContext
  );
  const [stores, setStores] = useState([] as StoreDTOProductList[]);
  const [storeFilters, setStoreFilters] = useState([] as StoreDTOProductList[]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [_t] = useTranslation();
  const [filteredProducts, setFilteredProducts] = useState([] as ProductDTO[]);

  const productFilter = useCallback(
    (product: ProductDTO) =>
      product.name.toLowerCase().includes(search.trim().toLowerCase()) &&
      (categoryFilter.length === 0 ||
        categoryFilter.find((category) => category === product.category)) &&
      (storeFilters.length === 0 ||
        storeFilters.some((storeFilter) =>
          storeFilter.products.includes(product.id)
        )),
    [categoryFilter, search, storeFilters]
  );

  const [loadingProduct, setLoadingProduct] = useState(false);
  useEffect(() => {
    setFilteredProducts(products.filter(productFilter));

    setTotalPages(Math.ceil(products.filter(productFilter).length / 24));
    setPage(0);
  }, [products, search, categoryFilter, storeFilters, productFilter]);

  const classes = makeStyles(() => ({
    typo: {
      backgroundColor: "#154868",
      color: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }))();

  useEffect(() => {
    let store = stores.find(({ id }) => id + "" === storeId);
    if (store) {
      setStore(store);
      setStoreFilters(store ? [store] : []);
    }
  }, [stores, storeId]);

  useEffect(
    () => setCategories([...new Set(products.map(({ category }) => category))]),
    [products]
  );

  useEffect(() => {
    if (processor) {
      setStoreFilters([
        {
          id: processor.id,
          products: processor.products.map((product) => product.id),
          name: processor.name,
          zip: processor.zip,
          city: processor.city,
        },
      ]);
    }
  }, [processor, processorId]);

  useEffect(() => {
    if (processorId)
      ApiService.getProcessor(processorId).then(setProcessor, (err) =>
        console.error("Processor List Error (getProcessor):", err)
      );

    ApiService.getProducts()
      .then(setProducts, (err) =>
        console.error("Product List Error (getProducts):", err)
      )
      .then(() =>
        ApiService.getProductStores().then(
          (stores) => {
            setStores(stores);
            setLoadingProduct(true);
          },
          (err) => console.error("Product List Error (getProductStores):", err)
        )
      );
  }, []);

  return (
    <Container>
      {storeId && store ? (
        <Typography variant={"h4"}>
          {store.name + " " + _t("assortment")}
        </Typography>
      ) : processorId && processor ? (
        <Typography variant={"h4"}>
          {processor.name + " " + _t("assortment")}
        </Typography>
      ) : (
        <></>
      )}
      <Grid container spacing={3}>
        <Grid item md={9} sm={8} xs={12}>
          {products.length && loadingProduct ? (
            [
              filteredProducts.length !== 0 && products.length !== 0 ? (
                <React.Fragment>
                  <Grid container spacing={2} alignItems={"stretch"}>
                    {filteredProducts
                      .slice(page * 24, page * 24 + 24)
                      .map((product) => (
                        <Grid item key={product.id} sm={4} md={4} lg={3}>
                          <ProductView {...product} />
                        </Grid>
                      ))}
                  </Grid>
                  <Pagination
                    variant="outlined"
                    style={{ marginTop: "0.5em" }}
                    color="primary"
                    page={page + 1}
                    count={totalPages}
                    onChange={(e, page) => setPage(page - 1)}
                  />
                </React.Fragment>
              ) : (
                <Typography component={"div"}>
                  <h1>{_t("NoProductsMatch")}</h1>
                </Typography>
              ),
            ]
          ) : (
            <>
              <LinearProgress />
              <Typography variant="h5"> {_t("loadingData")}</Typography>
            </>
          )}
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <ProductSearch {...{ search, onChange: (s) => setSearch(s) }} />
          <CategorySearch
            {...{
              categories,
              onChange: (flt) => {
                !categoryFilter.find((value) => value === flt)
                  ? addFilter([...categoryFilter, flt])
                  : addFilter(categoryFilter.filter((value) => value !== flt));
              },
            }}
          />
          {store ? (
            <Card className={classes.typo}>
              {productId ? (
                <Button
                  onClick={() =>
                    openPage("stores", { productId: productId }, ["storeId"])
                  }
                >
                  <Typography component={"div"}>
                    {_t("backToProduct")}
                  </Typography>
                </Button>
              ) : (
                <Button
                  onClick={() => openPage("stores", undefined, ["storeId"])}
                >
                  <Typography component={"div"}>{_t("backToStore")}</Typography>
                </Button>
              )}
            </Card>
          ) : processor ? (
            prevProductId === 0 ? (
              <></>
            ) : (
              <Card className={classes.typo}>
                <Button
                  onClick={() =>
                    openPage("products", { productId: prevProductId })
                  }
                >
                  <Typography component={"div"}>
                    {_t("backToProduct")}
                  </Typography>
                </Button>
              </Card>
            )
          ) : (
            <StoreSearch
              {...{
                stores,
                storeId,
                onChange: (flt) => setStoreFilters(flt),
              }}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default ProductListPage;
