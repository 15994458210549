/** External imports **/
// Material-UI imports
import { Card, CircularProgress, Grid, IconButton, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

// Leaflet imports
import { LatLng, LatLngBounds } from "leaflet";

// React imports
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMap } from "react-leaflet";

/** Internal imports **/
// Service imports
import ApiService from "../../../../services/ApiService";

// Atom imports
import MapControl from "../MapControl/MapControl";

// Style imports
import useStyles from './MapSearch.style';

/**
 * Renders the search box for the map component.
 * The user can use this search box to fetch coordinates from natural input.
 * 
 * @param setPosition Callback function for found coordinates.
 * @returns The rendered search box.
 * @author Joel Meccariello
 */
const MapSearch = (props: { position: "bottomleft" | "bottomright" | "topleft" | "topright" }) => {

    // Context variables
    const [_t] = useTranslation();
    const classes = useStyles();
    const map = useMap();

    // Stateful variables
    const [options, setOptions] = useState([] as { name: string, coords: { lat: number, lng: number }, bounds: { southWest: { lat: number, lng: number }, northEast: { lat: number, lng: number } } }[]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    // Timer variable for checking when the user stopped typing
    let timer: NodeJS.Timeout;

    // Updates the loading icon (doesn't work yet)
    useEffect(() => setLoading(open && options.length === 0 && (document.getElementById("autocomplete") as HTMLInputElement).value !== ""), [open, options]);

    // Creates the filter options for the autocomplete
    const filterOptions = createFilterOptions<{ name: string, coords: { lat: number, lng: number }, bounds: { southWest: { lat: number, lng: number }, northEast: { lat: number, lng: number } } }>({
        ignoreCase: true,
        limit: 4,
        ignoreAccents: true,
        stringify: searchResult => searchResult.name
    });

    /**
     * This helper function creates a LatLngBounds object from two corner coordinates.
     * 
     * @param bounds The south west and the north east corner of the bounds.
     * @returns LatLngBounds object from the given corners.
     * @author Joel Meccariello
     */
    const createBounds = (bounds: { southWest: { lat: number, lng: number }, northEast: { lat: number, lng: number } }) => new LatLngBounds(new LatLng(bounds.southWest.lat, bounds.southWest.lng), new LatLng(bounds.northEast.lat, bounds.northEast.lng));

    /**
     * This function fetches the coordinates associated with the query using the api service.
     * 
     * @param query The address to be geocoded.
     * @param first If true, uses the first object of the results array.
     * @author Joel Meccariello
     */
    const searchLocation = (query: string, first = false) => ApiService.searchLocation(query).then(results => {
        if (first) {
            map.fitBounds(createBounds(results[0].bounds));
            setOpen(false);
        }
        else setOptions(results);
    }).catch(console.error);

    /**
     * This function starts a search with the query and opens the suggestion list.
     * 
     * @param query User given string to be geocoded.
     * @author Joel Meccariello
     */
    const startAutocomplete = (query: string) => {
        searchLocation(query);
        setOpen(true);
    };

    // Search box to be rendered
    return <MapControl position={props.position}>
        <Card title={_t("search")} className={classes.card}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={10}>
                    <Autocomplete id={"autocomplete"} className={classes.autocomplete} fullWidth placeholder={_t("searchlocation")} freeSolo getOptionLabel={searchResult => typeof searchResult === "string" ? searchResult : searchResult.name} onInput={(e) => {
                        clearTimeout(timer);
                        if ((document.getElementById("autocomplete") as HTMLInputElement).value) timer = setTimeout(() => startAutocomplete((document.getElementById("autocomplete") as HTMLInputElement).value), 1000);
                    }} onChange={(e, query) => query ? searchLocation(typeof query === "string" ? query : query.name, true) : undefined} filterOptions={filterOptions} options={options} open={open} onClose={() => setOpen(false)} loading={loading} renderInput={params => <TextField placeholder={_t("searchlocation")} {...params} InputProps={{
                        ...params.InputProps, endAdornment: (<Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </Fragment>)
                    }} />} />
                </Grid>
                <Grid item xs={2}>
                    <IconButton onClick={(e, query = (document.getElementById("autocomplete") as HTMLInputElement).value) => query && query !== "" ? searchLocation(query, true) : undefined}>
                        <SearchIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Card>
    </MapControl>;
}

/** Exports **/
export default MapSearch;