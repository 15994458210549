/** External imports **/
// React imports
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CardButton from "../Button/CardButton";

// Material-UI imports
import {
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Typography,
} from "@material-ui/core";

/** Internal imports **/
// Atom imports
import getClasses from "./product.style";

// Context imports
import RoutingContext from "../../../context/RoutingContext";
/**
 * Displays a product's image and name.
 *
 * @param id The id of the product to display.
 * @param name The name of the product to display.
 * @returns The output to be rendered.
 * @author Joel Meccariello, Gianluca Daffré, Carlo Meier
 */
const ProductView = (product: { id: number; name: string }) => {
  // Parameters
  const { id, name } = product;

  // Style variables
  const classes = getClasses();

  // Context variables
  const { openPage } = useContext(RoutingContext);
  const [defaultImg, setDefaultImg] = useState(`${process.env.REACT_APP_IMG_URL}/${id}.jpg`);
  const [_t] = useTranslation();

  // Output to be rendered
  return (
    <Typography component={"div"}>
      <Card>
        <CardActionArea onClick={() => openPage("products", { productId: id })}>
          <CardMedia
            component={"img"}
            onError={() =>
              setDefaultImg(
                "./logo512.png"
              )
            }
            image={defaultImg}
            alt={_t("noimage")}
            className={classes.cardMedia}
          />
        </CardActionArea>
        <CardActions className={classes.cardContent}>
          <Typography variant="body2" component={"div"}>
            {!!name ? name : _t("noNameAvailable")}
          </Typography>
        </CardActions>
        <CardActions>
          <CardButton
            variant={"contained"}
            goToPage={{ page: "stores", params: { productId: id } }}
            child={_t("whereToBuy")}
          />
        </CardActions>
      </Card>
    </Typography>
  );
};
export default ProductView;
