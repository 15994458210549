/**
 * Used for fetching the business hours
 */
let googleMapsApiPlacesService: google.maps.places.PlacesService;

/**
 * Stores all previously opened business hours in order to minimize API calls
 */
export const businessHoursCache: { store: number, businessHours: { day: string, timeStrings: string[] }[] }[] = [];

/**
 * This function initializes the Google Maps API's places service.
 * 
 * @param googleMapsApi The Google Maps API maps object
 * @returns The Google Maps API's places service
 * @author Joel Meccariello
 */
export const initPlacesService = (googleMapsApi: google.maps.Map) => googleMapsApiPlacesService ?? (googleMapsApiPlacesService = new window.google.maps.places.PlacesService(googleMapsApi));

/**
 * This function fetches business hours of a given store.
 * 
 * @param id The store's ID
 * @param name The store's name
 * @param address The store's address
 * @param zip The store's zip code
 * @param city The store's city
 * @param businessHoursCallback A callback returning the store's business hours
 * @author Joel Meccariello
 */
export const fetchBusinessHours = (id: number, name: string, address: string, zip: string, city: string, businessHoursCallback: (businessHours: { day: string, timeStrings: string[] }[]) => void) => ((cachedBusinessHours = businessHoursCache.find(({ store }) => store === id)) => cachedBusinessHours ? businessHoursCallback(cachedBusinessHours.businessHours) : googleMapsApiPlacesService.findPlaceFromQuery({ query: `${name}, ${address}, ${zip} ${city}`, fields: ["place_id"] }, queryRes => queryRes && queryRes[0] && googleMapsApiPlacesService.getDetails({ placeId: queryRes[0].place_id as string, fields: ["opening_hours.weekday_text"] }, detailsRes => detailsRes?.opening_hours?.weekday_text && (() => {
    const businessHours = detailsRes.opening_hours.weekday_text.map(weekday => ({ day: weekday.substr(0, weekday.indexOf(' ') - 1), timeStrings: weekday.substr(weekday.indexOf(' ') + 1).split(", ") }));
    businessHoursCache.push({ store: id, businessHours });
    businessHoursCallback(businessHours);
})())))();