/** External imports **/
// Material-UI imports
import { Button, Card, Grid, Typography } from "@material-ui/core";

// Leaflet imports
import { Point } from "leaflet";

// React imports
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ProcessorDTOProcessorPage from "../../../config/dtos/processor.processor-page.dto";
import Producer from "../../../config/models/producer.model";

/** Internal imports **/
// Model imports
import Store from "../../../config/models/store.model";
import CustomButton from "../Button/CustomButton";
import BusinessHoursDialog from "./BusinessHoursDialog/BusinessHoursDialog";

// Style imports
import useStyles from "./LocationDisplay.style";

/**
 * Displays information about a store.
 *
 * @param props Contains the store to be displayed and the user's location (optional).
 * @returns The info card about the store.
 * @author Joel Meccariello, Gianluca Daffré, Carlo Meier
 */
const LocationDisplay = (props: {
  marker: Store | ProcessorDTOProcessorPage | Producer;
  type: "store" | "processor" | "producer";
  location: Point | null;
  googleMapsApiIsLoaded: boolean;
  googleMapsApi?: google.maps.Map;
}) => {
  // Params
  const { marker, type, location, googleMapsApiIsLoaded, googleMapsApi } =
    props;

  // Context variables
  const classes = useStyles();
  const [_t] = useTranslation();

  // Stateful variables
  const [dialogOpen, setDialogOpen] = useState(false);

  // Output to be rendered
  return (
    <Card title={marker.name} className={classes.card}>
      <Grid container>
        <Grid container item xs={12}>
          <Grid item sm={12}>
            <Typography component={"div"} className={classes.title}>
              <u>{marker.name ? marker.name : _t(`things.${type}`)}</u>
            </Typography>
          </Grid>
          <Grid item sm={12}>
            {location && marker.latitude && marker.longitude && (
              <Typography component={"div"} className={classes.distance}>
                {((
                  distance = calculateDistance(
                    location,
                    new Point(marker.latitude, marker.longitude)
                  )
                ) =>
                  distance < 1000
                    ? Math.round(distance / 25) * 25 + "m"
                    : Math.round(distance / 100) / 10 + "km")() + " entfernt"}
              </Typography>
            )}
            <Grid container spacing={1}>
              <Grid item sm>
                {type !== "producer" ? (
                  <Button
                    onClick={() =>
                      window.location.assign(
                        `?pg=products&${type}Id=${marker.id}`
                      )
                    }
                    variant={"text"}
                    color={"primary"}
                    className={classes.button}
                  >
                    {_t("assortment")}
                  </Button>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item sm>
                {marker.website && (
                  <a
                    href={
                      marker.website.substr(0, 4) === "http"
                        ? marker.website
                        : `http://${marker.website}`
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant={"text"}
                      color={"primary"}
                      className={classes.button}
                    >
                      {_t("visitSite")}
                    </Button>
                  </a>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm>
            <Typography component={"div"}>
              {marker.address ? (
                <>
                  {marker.address}
                  <br />
                </>
              ) : (
                <></>
              )}
              {marker.zip ? marker.zip : <></>}
              {marker.zip && marker.city ? <> </> : <></>}
              {marker.city ? marker.city : <></>}
            </Typography>
            {marker.phoneNumber && (
              <Typography component={"div"} className={classes.phoneNumber}>
                <strong>{_t("form.phone")}</strong>: {marker.phoneNumber}
              </Typography>
            )}
          </Grid>
        </Grid>
        {type === "store" && (
          <CustomButton
            variant="contained"
            color="primary"
            onClick={() => setDialogOpen(true)}
          >
            {_t("displayBusinessHours")}
          </CustomButton>
        )}
        {dialogOpen && (
          <BusinessHoursDialog
            store={marker}
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            googleMapsApiIsLoaded={googleMapsApiIsLoaded}
            googleMapsApi={googleMapsApi}
          />
        )}
      </Grid>
    </Card>
  );
};

// Exports this function
export default LocationDisplay;

/**
 * Calculates the distance from a given store to the user.
 *
 * @param location The user's location
 * @param store The location of the store
 * @returns The calculated distance in meters.
 * @author Joel Meccariello
 */
const calculateDistance = (location: Point, store: Point) =>
  ((p = 0.017453292519943295) =>
    Math.round(
      12742000 *
        Math.asin(
          Math.sqrt(
            0.5 -
              Math.cos((location.x - store.x) * p) / 2 +
              (Math.cos(store.x * p) *
                Math.cos(location.x * p) *
                (1 - Math.cos((location.y - store.y) * p))) /
                2
          )
        )
    ))();
