/** External imports **/
// Material-UI imports
import { Grid, Typography } from "@material-ui/core";

// Leaflet imports
import { Icon, IconOptions, LatLng, Point } from "leaflet";

// React imports
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AllLocaitons from "../../../config/dtos/all.locations";

/** Internal imports **/
// DTO imports
import ProcessorDTOProductPage from "../../../config/dtos/processor.product-page.dto";
import ProducerDTO from "../../../config/dtos/producer.dto";
import ProductDTOProductPage from "../../../config/dtos/product.product-page.dto";
import StoreDTOProductPage from "../../../config/dtos/store.product-page.dto";

// Icon imports
import {
  ICON_PROCESSOR,
  ICON_PRODUCER,
  ICON_STORE,
} from "../../../config/icons";

// Context imports
import RoutingContext from "../../../context/RoutingContext";

// Atom imports
import CustomButton from "../../atoms/Button/CustomButton";
import Map from "../../atoms/Map/Map";

/**
 * Displays a UI with a search box, a map with stores, processors and producers and an info box about a clicked store.
 *
 * @param props Contains an array of stores, may contain a product, may contain a center point and may contain a store's id.
 * @returns The output to be rendered.
 * @author Joel Meccariello, Carlo Meier
 */
const StoreMap = (props: {
  locations: AllLocaitons;
  product: ProductDTOProductPage | null;
  center?: LatLng;
}) => {
  // Parameters
  const { locations, product, center } = props;

  // Context variables
  const { history } = useContext(RoutingContext);
  const [_t] = useTranslation();

  // Stateful variables
  const [markerGroups, setMarkerGroups] = useState<
    {
      name: string;
      markers: {
        bundle: StoreDTOProductPage | ProcessorDTOProductPage | ProducerDTO;
        position: Point;
        icon: Icon<IconOptions>;
        onClick?: (params: {
          latlng: { lat: number; lng: number };
          target: { options: { bundle: StoreDTOProductPage } };
        }) => void;
      }[];
      checked?: boolean;
    }[]
  >([]);

  /** useEffects **/
  // Creates markers for stores, processors and producers
  useEffect(() => {
    let mGroups = [
      {
        name: "store",
        markers: locations.stores
          .map((store) => ({
            position: new Point(store.latitude, store.longitude),
            bundle: store,
            icon: new Icon(ICON_STORE),
          }))
          .filter(({ position }) => position.x && position.y),
      },
    ];
    if (!product) {
      mGroups.push(
        {
          name: "processor",
          markers: locations.processors
            .map((processor) => ({
              position: new Point(processor.latitude, processor.longitude),
              bundle: processor,
              icon: new Icon(ICON_PROCESSOR),
            }))
            .filter(({ position }) => position.x && position.y),
        },
        {
          name: "producer",
          markers: locations.producers
            .map((producer) => ({
              position: new Point(producer.latitude, producer.longitude),
              bundle: producer,
              icon: new Icon(ICON_PRODUCER),
            }))
            .filter(({ position }) => position.x && position.y),
        }
      );
    }

    setMarkerGroups(mGroups);
  }, [product, locations.stores, _t, locations]);

  /** Render **/
  return (
    <Grid container>
      {product ? (
        <>
          <Grid item xs={2}>
            <CustomButton
              color={"primary"}
              variant={"contained"}
              onClick={history.goBack}
            >
              <Typography
                component={"div"}
                style={{ textTransform: "capitalize" }}
              >
                {_t("back")}
              </Typography>
            </CustomButton>
          </Grid>
          <Grid item xs={8}>
            <Typography component={"div"} variant={"h4"} align={"center"}>
              <b>{`${_t("things.stores")} von ${
                product.name ?? _t("thisProduct")
              }`}</b>
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography component={"div"} variant={"h4"} align={"center"}>
            <b>{_t("things.stores")}</b>
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Map markerGroups={markerGroups} center={center} />
      </Grid>
    </Grid>
  );
};

// Export this function
export default StoreMap;
