/** External Imports **/
// Leaflet imports
import { useJsApiLoader } from "@react-google-maps/api";
import { Icon, IconOptions, LatLng, Point } from "leaflet";
// React imports
import React, { useEffect, useState } from "react";
import { Marker, Popup } from "react-leaflet";
import ProcessorDTOProcessorPage from "../../../../config/dtos/processor.processor-page.dto";
/** Internal Imports **/
// Config imports
import ProcessorDTOProductPage from "../../../../config/dtos/processor.product-page.dto";
import ProducerDTO from "../../../../config/dtos/producer.dto";
import StoreDTOProductPage from "../../../../config/dtos/store.product-page.dto";
import Producer from "../../../../config/models/producer.model";
import Store from "../../../../config/models/store.model";
import markers from "../../../../public/markers";
// Service imports
import ApiService from "../../../../services/ApiService";
import { initPlacesService } from "../../../../services/GoogleMapsAPI.service";
// Atom imports
import LocationDisplay from "../../LocationDisplay/LocationDisplay";
import useStyles from "../../LocationDisplay/LocationDisplay.style";

const GOOGLE_MAPS_API_LIBRARIES = ["places"] as (
  | "places"
  | "drawing"
  | "geometry"
  | "localContext"
  | "visualization"
)[];

/**
 * Displays markers on the map.
 *
 * @param props Contains the markerGroup and the client's location.
 * @returns The markers containing the location display as popup.
 * @author Joel Meccariello
 */
const MapMarkers = (props: {
  markerGroup: {
    name: string;
    markers: {
      bundle?: StoreDTOProductPage | ProcessorDTOProductPage | ProducerDTO;
      position: Point;
      icon: Icon<IconOptions>;
    }[];
  };
  location: Point | null;
  position: LatLng;
}) => {
  /** Parameters **/
  const { markerGroup, location } = props;

  /** Hooks **/
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_API_KEY as string,
    libraries: GOOGLE_MAPS_API_LIBRARIES,
    language: "de-CH",
  });

  /** Stateful Variables **/
  const [googleMapsApi, setGoogleMapsApi] = useState<google.maps.Map>();

  useEffect(() => {
    if (!googleMapsApi && isLoaded) {
      const googleMapsDiv = document.createElement("div");
      document.body.appendChild(googleMapsDiv);
      const api = new window.google.maps.Map(googleMapsDiv);
      setGoogleMapsApi(api);
      initPlacesService(api);
    }
  }, [isLoaded, googleMapsApi]);

  const [currentMarker, setCurrentMarker] = useState<
    Store | ProcessorDTOProcessorPage | Producer
  >();

  /** Rendering **/
  return (
    <>
      {markerGroup.markers
        .filter(({ position }) => position.x && position.y)
        .map((marker, j) => (
          <Marker
            key={`${marker.position.x}${marker.position.y}${j}`}
            {...marker}
            eventHandlers={{
              click: () => {
                marker.bundle &&
                  getValue(markerGroup, `${marker.bundle.id}`).then((value) =>
                    setCurrentMarker(value)
                  );
              },
            }}
            title={marker.bundle && marker.bundle.name}
            position={new LatLng(marker.position.x, marker.position.y)}
          >
            <Popup minWidth={300}>
              {currentMarker &&
                marker.bundle &&
                currentMarker.id === marker.bundle.id && (
                  <LocationDisplay
                    googleMapsApiIsLoaded={isLoaded}
                    googleMapsApi={googleMapsApi}
                    marker={currentMarker}
                    type={
                      markerGroup.name as "store" | "processor" | "producer"
                    }
                    location={location}
                  />
                )}
            </Popup>
          </Marker>
        ))}
    </>
  );
};

async function getValue(
  markerGroup: {
    name: any;
    markers?: {
      bundle?:
        | StoreDTOProductPage
        | ProcessorDTOProductPage
        | ProducerDTO
        | undefined;
      position: Point;
      icon: Icon<IconOptions>;
    }[];
  },
  markerId: string
): Promise<Store | ProcessorDTOProcessorPage | Producer> {
  return await ApiService[
    `get${markerGroup.name.charAt(0).toUpperCase()}${markerGroup.name.substring(
      1
    )}` as "getStore" | "getProcessor" | "getProducer"
  ](markerId).then((marker) => marker);
}

/** Export **/
export default MapMarkers;
