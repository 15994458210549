import React, { useContext } from "react";
import RoutingContext from "../../../context/RoutingContext";
import useStyles from "./Button.style";
import { Button, Typography } from "@material-ui/core";

const CustomButton = (props: {
  children?: JSX.Element;
  goToURL?: { href: string; target?: string };
  goToPage?: { page: string; params?: any };
  variant?: "text" | "outlined" | "contained";
  color?: "default" | "inherit" | "primary" | "secondary";
  onClick?: () => void;
}) => {
  const { children, goToPage, variant, color, goToURL, onClick } = props;

  const { openPage } = useContext(RoutingContext);

  const classes = useStyles();

  const openURL = (href: string, target?: string) =>
    window.open(href, target ? target : "_self");

  return (
    <Button
      onClick={() =>
        onClick
          ? onClick()
          : goToPage
            ? openPage(goToPage.page, goToPage?.params)
            : goToURL
              ? openURL(goToURL.href, goToURL.target)
              : undefined
      }
      variant={variant}
      color={color ?? "primary"}
      className={classes.button}
    >
      <Typography className={classes.btn}>{children}</Typography>
    </Button>
  );
};

export default CustomButton;
