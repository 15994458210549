import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
    color: "white",
    padding: theme.spacing(2),
    //textTransform: 'uppercase',
  },
  card: {
    marginBottom: "1rem",
    borderStyle: "solid",
    borderColor: theme.palette.primary.dark,
    borderWidth: 1,
  },
}));

export default useStyles;
