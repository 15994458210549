import {
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import markers from "../../../../public/markers";
import MapControl from "../MapControl/MapControl";
import useClasses from "./MapLegend.style";

const MapLegend = (props: {
  position: "bottomleft" | "bottomright" | "topleft" | "topright";
  data: string[];
}) => {
  const [_t] = useTranslation();
  const classes = useClasses();
  const { position, data } = props;

  const iconIndex: { [index: string]: string } = {
    store: markers.StoreMarkerURI,
    stores: markers.StoreMarkerURI,
    processor: markers.ProcessorMarkerURI,
    processors: markers.ProcessorMarkerURI,
    producer: markers.ProducerMarkerURI,
    producers: markers.ProducerMarkerURI,
  };

  return (
    <MapControl position={position}>
      <Card>
        <List dense disablePadding>
          {data.map((markerGroup, index) => (
            <ListItem className={classes.item} key={index}>
              <ListItemIcon className={classes.wrapper}>
                <img
                  src={iconIndex[markerGroup]}
                  className={classes.icon}
                  alt=""
                />
              </ListItemIcon>
              <ListItemText primary={_t(`things.${markerGroup}`)} />
            </ListItem>
          ))}
        </List>
      </Card>
    </MapControl>
  );
};

export default MapLegend;
