import React, { createContext, FC, useState } from "react";

export type ProductlistContextType = {
  categoryFilter: string[];
  prevProductId: number;
  addFilter: (filter: string[]) => void;
  addProductId: (id: number) => void;
};

const contextDefaultValues: ProductlistContextType = {
  categoryFilter: [],
  prevProductId: 0,
  addFilter: () => {},
  addProductId: () => {},
};

const ProductListPageContext =
  createContext<ProductlistContextType>(contextDefaultValues);

export const ProductListPageContextProvider: FC = ({ children }) => {
  const [categoryFilter, setCategoryFilter] = useState<string[]>(contextDefaultValues.categoryFilter);
  const [prevProductId, setPrevProductId] = useState<number>(contextDefaultValues.prevProductId);

  const addFilter = (newFilter: string[]) =>
    setCategoryFilter(newFilter.filter((flt) => flt !== ""));

  const addProductId = (newProductId: number) => setPrevProductId(newProductId);

  return (
    <ProductListPageContext.Provider
      value={{
        categoryFilter,
        prevProductId,
        addFilter,
        addProductId,
      }}
    >
      {children}
    </ProductListPageContext.Provider>
  );
};

export default ProductListPageContext;
