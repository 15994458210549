import { Button, List, ListItem, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { PermIdentity } from "@material-ui/icons";
import DomainIcon from "@material-ui/icons/Domain";
import LocationIcon from "@material-ui/icons/LocationOn";
import StorefrontIcon from "@material-ui/icons/Storefront";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import ProductListPageContext from "../../../context/ProductListPageContext";
import RoutingContext from "../../../context/RoutingContext";
import CustomButton from "../../atoms/Button/CustomButton";
import Timeline from "../../atoms/Timeline/Timeline";

const useStyles = makeStyles((theme) => ({
  icn: {
    fill: "white",
  },
  btn: {
    paddingInline: "16px",
    paddingTop: "6px",
    paddingBottom: "6px",
    marginLeft: 15,
    minWidth: "max-content",
    height: 30,
    "&:hover": {
      backgroundColor: "#154868",
    },
  },
  under_btn: {
    marginLeft: 15,
    marginTop: 15,
    paddingInline: "16px",
    paddingTop: "6px",
    paddingBottom: "6px",
    "&:hover": {
      backgroundColor: "#154868",
    },
    minWidth: "max-content",
    height: 30,
  },
  createStyles: {
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  },
  subtitle: {
    display: "block",
    fontSize: "1.3em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px",
    fontWeight: "bold",
  },
}));

const ManufacturingChain = (props: {
  product: {
    id: number;
    name: string;
    category: string;
    processor: {
      id: number;
      name: string;
      zip: string;
      city: string;
      website?: string;
      phoneNumber?: string;
      producers: {
        id: number;
        name: string;
        zip: string;
        city: string;
        phoneNumber?: string;
        website?: string;
      }[];
    };
  };
  rerender: boolean;
  setRerender: React.Dispatch<React.SetStateAction<boolean>>;
  setCenterId: React.Dispatch<React.SetStateAction<number | undefined>>;
}) => {
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    website: string
  ) => {
    window.location.href = website;
  };
  const { addProductId } = useContext(ProductListPageContext);

  const { id, name, zip, city, producers } = props.product.processor;
  const [_t] = useTranslation();
  const classes = useStyles();
  const { openPage } = useContext(RoutingContext);

  const blocks: {
    title: string;
    content?: JSX.Element;
    actionArea?: JSX.Element;
    img?: JSX.Element;
  }[] = [
    {
      title: _t("things.stores"),
      actionArea: (
        <div>
          <Grid container spacing={3}>
            <Grid item lg={6}>
              <Typography component={"div"}>
                <CustomButton
                  variant={"contained"}
                  goToPage={{
                    page: "stores",
                    params: { productId: props.product.id },
                  }}
                >
                  {_t("showStores")}
                </CustomButton>
              </Typography>
            </Grid>
          </Grid>
        </div>
      ),
      img: <StorefrontIcon className={classes.icn} />,
    },
    {
      title: _t("things.distributor"),
      content: (
        <div>
          <Typography className={classes.subtitle}>{_t("naturli.companyName")}</Typography>
          <Typography>{_t("naturli.description")}</Typography>
        </div>
      ),
      img: <PermIdentity className={classes.icn} />,
    },
    {
      title: _t("things.producer"),
      actionArea: (
        <div>
           <Typography className={classes.subtitle}>{name}</Typography>
          <LocationIcon /> {zip} {city}
          <Button
            variant={"contained"}
            color={"primary"}
            className={classes.btn}
            onClick={() => {
              addProductId(props.product.id);
              openPage("products", { processorId: id });
            }}
          >
            {_t("view")}
          </Button>
          <div>
            {!!props.product.processor.website ? (
              <Button
                target="_blank"
                variant={"contained"}
                color={"primary"}
                className={classes.under_btn}
                href={props.product.processor.website}
              >
                {_t("HomePage") + name}
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      ),
      img: <DomainIcon className={classes.icn} />,
    },
    {
      title: _t("milkSuppliers"),
      content: (
        <div>
          <List>
            {!!producers ? (
              producers.map(({ id, name, zip, city, phoneNumber, website }) => (
                <ListItem
                  button
                  key={id}
                  onClick={(event) => {
                    website && handleListItemClick(event, website);
                    props.setRerender(!props.rerender);
                    props.setCenterId(id);
                  }}
                >
                  <Typography component={"div"}>
                    {name} {zip} {city}
                  </Typography>
                </ListItem>
              ))
            ) : (
              <Typography component={"div"}>{_t("noMilkSuppliers")}</Typography>
            )}
          </List>
        </div>
      ),
      img: <PermIdentity className={classes.icn} />,
    },
  ];

  return (
    <Typography component={"div"}>
      <Timeline blocks={blocks} />
    </Typography>
  );
};

export default ManufacturingChain;
