import { createTheme } from "@material-ui/core";

export default createTheme({
  typography: {
    fontFamily: "BrandonTextWeb"
  },
  palette: {
    primary: {
      main: "#154868",
    },
    background: {
      default: "#ffffff",
    },
    text: {
      primary: "#808080",
      secondary: "#3a3a3a",
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiButton: {
      text: {
        color: "white",
      },
    },
    MuiCardHeader: {
      root: {
        backgroundColor: "#154868",
        color: "#FFFFFF",
      },
    },
    MuiCardActions: {
      root: {
        backgroundColor: "#154868",
        color: "#FFFFFF",
      },
    },
    MuiCard: {
      root: {
        borderRadius: 0,
      }
    },
    MuiChip: {
      root: {
        backgroundColor: "#FFFFFF"
      }
    }
  },
});
