import {
  Card,
  CardActions,
  CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CardButton from "../Button/CardButton";
import useClasses from "./product-details.style";


const ProductDetailView = (product: {
  id: number;
  name: string;
  category: string;
  processor: { zip: string };
  className?: string;
}) => {
  const { id, category, processor, className } = product;
  const [_t] = useTranslation();
  const classes = useClasses();
  const [defaultImg, setDefaultImg] = useState(
    `${process.env.REACT_APP_IMG_URL}/${id}.jpg`
  );

  return (
    <Card className={className}>
      <CardMedia
        component={"img"}
        onError={() =>
          setDefaultImg(
           "./logo192.png"
          )
        }
        image={defaultImg}
        alt={_t("noimage")}
        className={classes.cardMedia}
      />
      <CardActions>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography className={classes.titletext}>
              {product.name ? product.name : _t("noNameAvailable")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CardButton
              variant={"contained"}
              goToPage={{ page: "stores", params: { productId: id } }}
              child={_t("whereToBuy")}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {!!category
                ? _t("productProperties.category") + ": " + category
                : _t("NoCategory")}
              <br />
              {_t("productProperties.region") +
                ": " +
                parseRegion(processor.zip)}
            </Typography>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};
// prettier-ignore
const parseRegion = (zip: string) => ((n = parseInt(zip.charAt(0)), [_t] = useTranslation()) => n === 1 ? _t("regions.southwestSwitzerland") : n === 2 ? _t("regions.northwestSwitzerland") : n === 3 ? _t("regions.berne") : n === 4 ? _t("regions.basel") : n === 5 ? _t("regions.aargau") : n === 6 ? _t("regions.centralSwitzerlandWithTicino") : n === 7 ? _t("regions.grisons") : n === 8 ? _t("regions.zurichAndThurgau") : n === 9 ? _t("regions.eastSwitzerland") : _t("regions.notFound"))();
export default ProductDetailView;
