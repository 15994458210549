import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
    title: {
        fontWeight: 600,
        color: "#144669"
    },
    weekday: {
        textAlign: "right",
        fontWeight: 600,
        color: "#144669",
        padding: "6px"
    },
    businessHours: {
        textAlign: "center",
        padding: "6px"
    },
    altText: {
        textAlign: "center",
        fontSize: "120%"
    }
}));