/** External Imports **/
// Material-UI imports
import {
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";

// React imports
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** Internal Imports **/
// Service imports
import { fetchBusinessHours } from "../../../../services/GoogleMapsAPI.service";

// Atom imports
import LoadingIndicator from "../../loading-indicator/loading-indicator.view";

// Style imports
import useClasses from "./BusinessHoursDialog.style";

/**
 * Displays a dialog box containing the business hours of a given store.
 *
 * @param props Contains a store, an close-event listener, an "open"-flag, an indicator for Google Maps API loading status and the Google Maps API map.
 * @returns The rendered business hours table as dialog.
 * @author Joel Meccariello
 */
const BusinessHoursDialog = (props: {
  onClose: () => void;
  open: boolean;
  store: {
    id: number;
    name: string;
    address: string;
    zip: string;
    city: string;
  };
  googleMapsApiIsLoaded: boolean;
  googleMapsApi?: google.maps.Map;
}) => {
  // Parameters
  const { onClose, open, store, googleMapsApiIsLoaded, googleMapsApi } = props;

  // Hooks
  const [_t] = useTranslation();
  const classes = useClasses();

  // Stateful variables
  const [businessHours, setBusinessHours] =
    useState<{ day: string; timeStrings: string[] }[]>();
  const [isFetchingDone, setFetchingDone] = useState(false);

  /** useEffects **/
  useEffect(() => {
    if (googleMapsApiIsLoaded && googleMapsApi)
      fetchBusinessHours(
        store.id,
        store.name,
        store.address,
        store.zip,
        store.city,
        (res) => {
          setBusinessHours(res);
          setFetchingDone(true);
        }
      );
    
  }, [
    googleMapsApiIsLoaded,
    googleMapsApi,
    store.id,
    store.name,
    store.address,
    store.zip,
    store.city,
  ]);

  /** Return **/
  return (
    <Dialog onClose={onClose} open={open} maxWidth={false}>
      <DialogTitle style={{ textAlign: "center" }}>
        <span className={classes.title}>{_t("businessHours")}</span>
      </DialogTitle>
      {businessHours ? (
        <Table>
          <TableBody>
            {businessHours.map(({ day, timeStrings }) => (
              <TableRow key={day}>
                <TableCell className={classes.weekday}>{day}</TableCell>
                <TableCell className={classes.businessHours}>
                  {timeStrings.map((timeString) => (
                    <span key={`${day}${timeString}`}>
                      {timeString}
                      <br />
                    </span>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : isFetchingDone ? (
        <Typography className={classes.altText}>
          {_t("notAvailable")}
        </Typography>
      ) : (
        <LoadingIndicator
          label={_t("loadingBusinessHours")}
          interval={250}
          className={classes.altText}
        />
      )}
    </Dialog>
  );
};

/** Exports **/
export default BusinessHoursDialog;
