import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import ProductListPage from "./ProductListPage";
import StoreFinderPage from "./StoreFinderPage";
import RoutingContext, { PAGE_ATTR, POSSIBLE_PARAMS } from "../../context/RoutingContext";
import ProductDetailPage from "./ProductDetailPage/ProductDetailPage";
import ProcessorPage from "./ProcessorDetailPage";

function getPage(page?: string, param?: string) {
  switch (page) {
    case 'products':
      return param === "productId" ? <ProductDetailPage /> : param === "processorId" ? <ProcessorPage /> : <ProductListPage />;
    case 'stores':
      return <StoreFinderPage />;
    default:
      return <div>404</div>;
  }
}

const NaturliPage = () => {
  const { openPage, query } = useContext(RoutingContext);
  const [value, setValue] = useState<string>();
  const [param, setParam] = useState<string>();

  useEffect(() => {
    if (query) {
      setValue(query.get(PAGE_ATTR) ? query.get(PAGE_ATTR) as string : "products");
      setParam(POSSIBLE_PARAMS.find(possibleParam => query.has(possibleParam)));
    }
  }, [query]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    openPage(newValue);
    setValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor={"primary"}
        centered
      >
        <Tab label="Produkte" value={"products"} />
        <Tab label="Verkaufsstellen" value={"stores"} />
      </Tabs>
      <div style={{ paddingTop: 15 }}>{getPage(value, param)}</div>
    </div>
  );
};

export default NaturliPage;
