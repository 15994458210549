import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  () => ({
    button: {
      "&:hover": {
        backgroundColor: "#154868",
      },
      backgroundColor: "#154868",
      color: "#fff",
      minWidth: "max-content",
      height: 30,
    },
    btn: {
      fontSize: 14,
      paddingInline: "16px",
      paddingTop: "6px",
      paddingBottom: "6px",
    },
    crd_btn: {
      backgroundColor: "#E0E0E0",
      color: "#0F364F",
      height: 30,
      fontSize: 10,
      width: "100%",
    },
  }),
  { index: 1 }
);
