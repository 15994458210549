import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
    card: {
        paddingLeft: 5
    },
    form: {
        margin: 0
    },
    autocomplete: {
        width: 200
    }
}));