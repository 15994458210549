import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  cardContent: {
    height: "48px",
  },
  cardMedia: {
    height: "200px"
  }
}));
